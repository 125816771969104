import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { QueryClient, QueryClientProvider } from 'react-query'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/en-nz'

import '@fontsource-variable/public-sans'
import './index.css'
import theme from './theme'

import AppMainLayout from './layout/AppMainLayout'
import isDebugEnabled from './utils/debug'
import logger from './utils/logger'
import { hasAdminRole, isAuthenticated } from './utils/auth'
import appRoutes from './routes/AppRoutes'
import SignIn from './routes/sign-in'
import FullPageError from './components/app/FullPageError'
import AppDialogProvider from './hooks/appDialog/AppDialogProvider'
import queryClient from './utils/queryClient'

LicenseInfo.setLicenseKey(
  '9642a066f63647fa2f89072fc7b8d78dTz0xMDM3NDMsRT0xNzY1MDczNDMxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
)

if (isDebugEnabled()) {
  logger.enable()
}

// Setup date handling for the app.
dayjs.extend(localizedFormat)
dayjs.locale('en-nz')

/**
 * The main router for the app, the application layout and routes that aren't
 * within that (e.g. the sign in page) are defined here. They're expected to
 * change infrequently and there aren't many of them.
 *
 * Note that the content in here is included in the main app bundle, so it's
 * all downloaded before anything is rendered - don't put heaps of things in
 * here without introducing lazy loading of the modules.
 */
export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppMainLayout />,
    handle: {
      pageTitle: 'Home',
    },
    loader: async () => {
      if (!(await isAuthenticated()) || !(await hasAdminRole())) {
        return redirect('/sign-in')
      }
      return null
    },
    errorElement: <FullPageError marginLeft="230px" />,
    children: appRoutes,
  },
  {
    path: '/sign-in',
    element: <SignIn />,
  },
  {
    path: '*',
    element: (
      <FullPageError
        errorMessage={`Sorry that page doesn't exist yet.`}
        secondaryMessage={`If this isn't what you expected please let the engineering team know what you were doing when this happened.`}
      />
    ),
  },
])

// Actually render the app to the DOM.
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <AppDialogProvider>
          <RouterProvider router={router} />
        </AppDialogProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
)
