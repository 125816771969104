import { defer } from 'react-router-dom'
import AdminApi from '../../api/adminApi'
import useCompanyStore from './store'

export default {
  getAll: async function () {
    const companies = AdminApi.get('/companies')
    const metrics = AdminApi.get('/customer-usage/metrics?tags=HealthScore')

    return defer({
      data: Promise.all([companies, metrics]),
    })
  },
  get: async function ({ params }: any) {
    const getApiClients = useCompanyStore.getState().getApiClients(params.id)
    const getCompany = useCompanyStore.getState().getCompany(params.id)
    const getAvailableScopes = useCompanyStore.getState().getAvailableScopes()
    const getFeatureFlags = useCompanyStore
      .getState()
      .getFeatureFlags(params.id)
    return defer({
      data: Promise.all([
        getApiClients,
        getCompany,
        getAvailableScopes,
        getFeatureFlags,
      ]),
    })
  },
}
