import { NavLink, useMatches } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Fragment } from 'react'

const BreadcrumbNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  fontSize: '14px',
}))

const BreadcrumbSeparator = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

/**
 * Renders each segment of the route in a breadcrumb style, each segment is a
 * link to the route.
 */
const Breadcrumb = () => {
  const routeData = useMatches()
  return (
    <>
      {routeData.map((routeSegment, idx) => (
        <Fragment key={idx}>
          {idx !== 0 && <BreadcrumbSeparator> / </BreadcrumbSeparator>}
          <BreadcrumbNavLink to={routeSegment.pathname}>
            {typeof routeSegment.handle?.pageTitle === 'function'
              ? routeSegment.handle.pageTitle()
              : routeSegment.handle?.pageTitle}
          </BreadcrumbNavLink>
        </Fragment>
      ))}
    </>
  )
}
export default Breadcrumb
